import rehypeStringify from "rehype-stringify";
import remarkParse from "remark-parse";
import remarkRehype from "remark-rehype";
import { unified } from "unified";
import rehypeParse from "rehype-parse";
import rehypeRemark from "rehype-remark";
import remarkStringify from "remark-stringify";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { compile } from "@mdx-js/mdx";
import { visit } from "unist-util-visit";
import { Buffer } from "buffer";

const divideContent = (content) => {
  const frontmatterRegex = /---\s*\n([\s\S]*?)\n---/;
  const match = content.match(frontmatterRegex);

  if (!match) {
    let markdown = content.trim();
    let hasComponents = checkForReactOrOtherComponents(markdown);
    if (hasComponents) markdown = replaceReactOrOtherComponents(markdown);
    return {
      frontmatter: "",
      markdown: markdown,
    };
  }

  const frontmatter = match[1];
  let markdown = content.slice(match[0].length).trim();
  let hasComponents = checkForReactOrOtherComponents(markdown);
  if (hasComponents) markdown = replaceReactOrOtherComponents(markdown);
  return {
    frontmatter,
    markdown,
  };
};

export function checkForReactOrOtherComponents(content) {
  // Updated to handle both self-closing and regular tags
  const componentRegex = /<([A-Z][a-zA-Z]*)[^>]*(?:\/\>|\>[\s\S]*?<\/\1>)/;
  return componentRegex.test(content);
}

export function replaceReactOrOtherComponents(content) {
  const codeBlockRegex = /```{1,4}[\s\S]*?```{1,4}/g;
  const codeBlocks = [...content.matchAll(codeBlockRegex)].map(match => ({
    start: match.index,
    end: match.index + match[0].length
  }));

  const isInCodeBlock = (position) => {
    return codeBlocks.some(block => 
      position >= block.start && position <= block.end
    );
  };

  const componentRegex = /<([A-Z][a-zA-Z]*)((?:\s+[a-zA-Z]+(?:=(?:["'][^"']*["']|{[^}]*}))?)*)(?:\/\>|\>([\s\S]*?)<\/\1>)/g;

  return content.replace(
    componentRegex,
    (match, componentName, propsString, children, offset) => {
      if (isInCodeBlock(offset)) {
        return match;
      }

      const props = {};
      const propsRegex = /([a-zA-Z]+)=(?:["']([^"']*)["']|{([^}]*)})/g;
      let propMatch;

      while ((propMatch = propsRegex.exec(propsString)) !== null) {
        const [, propName, quotedValue, bracketValue] = propMatch;
        props[propName] = quotedValue || bracketValue;
      }

      if (children && children.trim()) {
        props.children = children.trim();
      }

      let encodedComponent = Buffer.from(match).toString("base64");
      let encodedProps = Buffer.from(JSON.stringify(props)).toString("base64");
      
      return `<component name="${componentName}" original="${encodedComponent}" props="${encodedProps}"></component>`;
    }
  );
}

function remarkComponent() {
  return (tree) => {
    visit(tree, "html", (node) => {
      if (node.value.startsWith("<component")) {
        // Extract all component attributes
        const componentNameMatch = node.value.match(/name="([^"]*)"/);
        const componentMatch = node.value.match(/original="([^"]*)"/);
        const propsMatch = node.value.match(/props="([^"]*)"/);

        if (componentNameMatch || componentMatch || propsMatch) {
          node.type = "component";
          node.data = {
            hName: "component",
            hProperties: {
              name: componentNameMatch ? componentNameMatch[1] : null,
              original: componentMatch ? componentMatch[1] : null,
              props: propsMatch ? propsMatch[1] : '{}'
            },
          };
        }
      }
    });
  };
}

export async function markdownToHtml(fileContent) {
  try {
    let { markdown, frontmatter } = divideContent(fileContent);
    console.log("markdown", markdown);
    console.log("frontmatter", frontmatter);
    let file = await unified()
      .use(remarkParse) // Parse markdown content to a syntax tree
      .use(remarkGfm)
      .use(remarkComponent)
      .use(remarkRehype) // Turn markdown syntax tree to HTML syntax tree, ignoring embedded HTML
      .use(rehypeStringify) // Serialize HTML syntax tree
      .process(markdown);
    console.log("file", file);
    console.log("what is file", file);
    return { content: String(file), frontmatter };
  } catch (error) {
    console.error(error);
    return "";
  }
}

async function handleReactTipTapComponents(htmlContent) {
  console.log("htmlContent in handle react tiptap", htmlContent);
  const file = await unified()
    .use(rehypeParse, {
      fragment: true,
      space: "html",
    })
    .use(rehypeRemark, {
      allowDangerousHtml: true,
      handlers: {
        component: (h, node) => {
          console.log("node", node);
          let original = Buffer.from(
            node.properties.original,
            "base64"
          ).toString();
          // Base64 decode props if they exist/
          console.log("this is the original", original);
          // Preserve component tags as raw HTML
          return {
            type: "html",
            value: original,
          };
        },
      },
    })
    .use(remarkGfm)
    .use(remarkStringify, {
      allowDangerousHtml: true,
      fences: true,
    })
    .process(htmlContent);
  return String(file);
}

export async function htmlToMarkdown(htmlContent) {
  try {
    console.log("htmlContent", htmlContent);
    if (htmlContent.includes("<component")) {
      console.log("htmlContent", htmlContent);
      return handleReactTipTapComponents(htmlContent);
    }
    const file = await unified()
    .use(rehypeParse)  // Parse HTML content to a syntax tree
    .use(rehypeRemark) // Turn HTML syntax tree to markdown syntax tree
    .use(remarkGfm)
    .use(remarkStringify) // Serialize markdown syntax tree
    .process(htmlContent);
    return String(file);
  } catch (error) {
    console.log(error);
    console.error(error);
    return "";
  }
}
