<template>
    <Toast />
    <Skeleton v-if="loading" width="100%" height="10rem"></Skeleton>
    <div v-else class="flex flex-col gap-[1.25rem] mt-[1.25rem]">
        <div style="display: flex; flex-direction: column;" class="flex flex-col gap-[1.25rem]">
            <div style="display: flex; flex-direction: column !important; gap: 0.62em;" class="">
                <label for="folders">What existing docs do you want to use as context</label>
                <TreeSelect v-model="selectedFolders" :options="treeNodes" selectionMode="checkbox" 
                    placeholder="Select item(s)" class="w-full !border-none !bg-[#1c1c1c]" />
                <label for="folders">Branch to push for docs at: {{ documentationRepoName }}</label>
                <BranchPicker v-if="branchToPush" v-model="branchToPush" :options="branches" />
                <Skeleton class="mt-2" v-if="!treeNodes.length" height="2rem" fluid />
            </div>
        </div>
        <div class="flex flex-col gap-[0.62rem]">
            <span class="w-full p-card-subtitle">Your task for Dev-Docs AI</span>
            <div style="display: flex; position: relative;">
                <Textarea placeholder="Type your document prompt here."
                    class="w-full rounded-[0.5rem] !border-none !bg-[#1c1c1c] px-[0.63rem] py-[0.44rem] h-[9.375rem]"
                    autoResize v-model="value" variant="filled" autofocus fluid rows="2" />
            </div>
        </div>
        <div class="flex flex-col justify-start items-start gap-[0.62rem]">
            <div class="self-stretch text-white/50 text-sm font-normal font-['Inter'] leading-[21px]">
                Popular prompts:
            </div>
            <div class="self-stretch flex flex-col justify-start items-start gap-[0.62rem]">
                <div v-for="(prompt, index) in popularPrompts" :key="index"
                    class="px-[0.44rem] py-[0.06rem] bg-[#7984eb]/25 rounded-[0.5rem] border border-[#7984eb] justify-start items-start inline-flex cursor-pointer">
                    <div @click="setPrompt(prompt)"
                        class="text-[#d3d3d3] text-xs font-normal font-['Inter'] leading-[18px]">
                        {{ prompt }}
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-end mt-4">
            <Button @click="generateContent" label="Submit" />
        </div>

    </div>
</template>


<script>
import ApiKey from '@/views/ApiKey.vue';
import { Editor, EditorContent, VueNodeViewRenderer, FloatingMenu } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import MarkdownEditor from '@/components/NewMarkdownEditor.vue';
import BranchPicker from "@/components/EditorComponents/BranchPicker.vue";
import ContextSelectTree from './ContextSelectTree.vue';
import { getFileContent, getCustomFiles, getFiles, getOwnerAndRepo, getSyncedDocsRepo, getCustomBranches, getSyncedDocsRepoWithCustomOrg} from '../plugins/devdocsBackendService.js';
import { useToast } from 'primevue/usetoast';
import Toast from 'primevue/toast';

function findParentPaths(items) {
    const paths = Array.isArray(items) ? items : [];
    return paths.filter((path, index) => {
        return !paths.some((otherPath, otherIndex) => {
            if (index === otherIndex) return false;
            return path.startsWith(otherPath + '/');
        });
    });
}

export default {
    props: {
        repo: String,
        owner: String,
        branch: String,
        org: String,
        prInfo: Object,
        files: {
            type: Array,
            default: () => []
        }
    },
    components: {
        EditorContent,
        FloatingMenu,
        MarkdownEditor,
        ContextSelectTree,
        BranchPicker
    },
    data() {
        return {
            value: '',
            additionalOptionsValue: null,
            locationValue: 'docs',
            items: ["docs", "blog"],
            loading: false,
            response: null,
            documentationRepoName: null,
            codeRepo: {},
            githubRepository: null,
            selectedFolders: {},
            folderObject: {},
            folderOptions: [],
            codeFiles: [],
            branches: [],
            branchToPush: null,
            editorOptions: [
                "Edit raw markdown", "Use rich text editor(preview)"
            ],
            editorSelectvalue: 'Edit raw markdown',
            openapiOptions: [],
            openapiRoutes: [],
            draftContent: null,
            additonalContextOptions: [
                { name: "openapi files", value: "openapi files" }
            ],
            treeNodes: [],
            editor: null,
            updatedContextSource: true,
            repoContextItems: [],
            popularPrompts: [
                "Provide a high-level overview of core features and use cases for different developer roles.",
                "Offer a step-by-step troubleshooting guide or link to a FAQ.",
                "Generate a blog post on most recent updates"
            ]
        }
    },
    async mounted() {
        try {
            let component = this
            let codeRepo = { owner: this.owner, repo: this.repo }
            this.codeRepo = codeRepo
            this.githubRepository = `${codeRepo?.owner}/${codeRepo?.repo}`
            await this.getCodeFiles()
            let customSyncedRepo = await getSyncedDocsRepoWithCustomOrg({ org: this.org })
            this.documentationRepoName = `${customSyncedRepo.owner}/${customSyncedRepo.repo}`
            console.log("what is the custom synced repo", customSyncedRepo)
            let branches = await getCustomBranches({ owner: customSyncedRepo.owner, repo: customSyncedRepo.repo, org: this.org })
            this.branches = branches?.branches 
            this.branchToPush = branches?.branches[0]

        } catch (e) {
            console.log(e)
        }


    },
    watch: {
        additionalOptionsValue: function (val) {
            console.log("what is the value", val)
        },
        openapiRoutes: function (val) {
            console.log("what is the value", val)
        },
        selectedFolders: function (val) {
            console.log("what is the value", val)
        }
    },
    methods: {
        search(event) {
            this.items = ["docs", "blog"]
        },
        async getRepos() {
            var myHeaders = new Headers();
            try {
                var token = await this.$authInstance.getToken()

                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                let url = await this.$authInstance.getBaseUrl()
                let response = await fetch(`${url}/github_app_repos`, requestOptions)
                let jsonResponse = await response.json()
                //this.repos = jsonResponse?.repos
                return jsonResponse?.repos
            } catch (e) {
                //this.folderOptions = []
            }
        },
        setPrompt(prompt) {
            this.value = prompt
        },
        async getCodeFiles() {
            var myHeaders = new Headers();
            try {
                let files = await getCustomFiles({ branch: this.branch, owner: this.owner, repo: this.repo, customOrg: this.org })
                console.log("what is the files", files)
                //this.treeNodes = await this.turnIntoNodes(jsonResponse)
                this.folderOptions = files?.files
                this.treeNodes = this.convertPathsToTree(files?.files || []);
                this.preSelectFiles();
            } catch (e) {
                this.folderOptions = []
                this.treeNodes = []
            }
        },
        async turnIntoNodes(folderArray) {

            // Helper function to create a node
            const createNode = (key, label, isFile = false) => ({
                key,
                label,
                data: key,
                icon: isFile ? 'pi pi-fw pi-file' : 'pi pi-fw pi-folder',
                children: []
            });

            // Create root node
            const root = createNode('0', 'Documents');

            for (let path of folderArray) {
                const parts = path.split('/');
                let currentLevel = root.children;
                let currentPath = '';

                // Iterate through each part of the path
                parts.forEach((part, index) => {
                    currentPath = currentPath ? `${currentPath}/${part}` : part;
                    const isFile = index === parts.length - 1 && part.includes('.');

                    // Check if node already exists at current level
                    let existingNode = currentLevel.find(node => node.label === part);

                    if (!existingNode) {
                        // Create new node
                        existingNode = createNode(currentPath, part, isFile);
                        currentLevel.push(existingNode);
                    }


                    // Move to next level
                    currentLevel = existingNode.children;
                });
            }

            return [root];


        },
        async generateContent() {
            this.loading = true
            var myHeaders = new Headers();
            try {
                let org = this.org
                let token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");

            
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`);
                }


                //let folders = this.processSelectedFolders(this.selectedFolders)
                let files = Object.keys(this.selectedFolders)
           
                let codeObjects = this.codeFiles
                delete codeObjects["Documentation"]
                let prompt = this.value
                let page = false
                let requestBody = { prompt, owner: this.owner, repo: this.repo, files, branch: this.branch, prInfo: this.prInfo, branchToPush: this.branchToPush }
                const raw = JSON.stringify(requestBody);
                const requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'  // 'as const' ensures TypeScript understands this as a literal type
                };

                let url = await this.$authInstance.getUrl()
                console.log("this raw object", raw)
                const saveResponseTwo = await fetch(`${url}/company/${org}/generate_docs_from_pr`, requestOptions);
                if (saveResponseTwo.status !== 200) {
                    const { message: responseTwoMessage } = await saveResponseTwo.json();
                    if (responseTwoMessage) {
                        this.$toast.add({
                            severity: "Danger",
                            summary: "Error",
                            detail: responseTwoMessage,
                            life: 3000,
                        });
                    }
                }
                this.loading = false
                this.value = ''
                const result = await saveResponseTwo.json();
                console.log("what is the result", result)
            } catch (e) {
                console.log(e)
                return {}
            }
        },
        async updateBlock() {
            let content = this.draftContent
            // this.updateAttributes({ saved: true })
            var myHeaders = new Headers();
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var raw = JSON.stringify({ message: options.historicalMessage || this.command })
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/proxy_url/message`, requestOptions)
                var result = await saveResponseTwo.json()


                this.output = result?.content
                this.command = ""


            } catch (e) {

            }
        },
        convertPathsToTree(paths) {
            const root = {
                key: '0',
                label: 'Documentation',
                data: 'Documentation Folder',
                icon: 'pi pi-fw pi-folder',
                children: []
            };

            paths.forEach(path => {
                const parts = path.split('/');
                let currentNode = root;
                let currentPath = '';

                parts.forEach((part, index) => {
                    currentPath = currentPath ? `${currentPath}/${part}` : part;
                    const isFile = index === parts.length - 1;
                    const nodeKey = currentPath;
                    
                    let existingNode = currentNode.children.find(n => n.label === part);
                    
                    if (!existingNode) {
                        existingNode = {
                            key: nodeKey,
                            label: part,
                            data: currentPath,
                            icon: isFile ? 'pi pi-fw pi-file' : 'pi pi-fw pi-folder',
                            children: []
                        };
                        currentNode.children.push(existingNode);
                    }
                    
                    currentNode = existingNode;
                });
            });

            return [root];
        },

        preSelectFiles() {
            const selections = {};
            this.files.forEach(file => {
                selections[file] = {
                    checked: true,
                    partialChecked: false
                };
                
                // Also check parent folders
                const parts = file.split('/');
                let currentPath = '';
                parts.forEach(part => {
                    currentPath = currentPath ? `${currentPath}/${part}` : part;
                    if (currentPath !== file) {
                        selections[currentPath] = {
                            checked: false,
                            partialChecked: true
                        };
                    }
                });
            });
            this.selectedFolders = selections;
        }
    }
}

</script>