<template>
    <Dialog id="setup_action" header="Finish Github App Setup" v-model:visible="openSetupModal">
        <div class="p-card-subtitle">Associate your Org in Dev-Docs to your repo</div>
        <div class="mt-8">
            <InputText class="w-full" v-model="username" placeholder="Username" disabled />
            <div class="flex items-center mt-4">
                <ul class="mr-4">
                    <li v-for="repo in repos">
                        {{ repo }}
                    </li>
                </ul>
                <Button icon="pi pi-arrow-right" text rounded aria-label="Filter" />
                <Select v-model="org" :options="orgs" placeholder="Select a City" class="w-full h-11 md:w-56" />
            </div>

        </div>
        <div class="mt-4" style="display: flex; flex-direction: row;">
            <Button label="Complete Github App Setup" class="w-full" @click="completeSetup"
                :disabled="!org || !repos.length">
                Complete Github App Setup
            </Button>
        </div>

    </Dialog>
    <Dialog header="User-Facing Docs Setup" v-model:visible="userFacingSetupModal">
        <div class="flex justify-start gap-2 mt-2 mb-2">
            <SelectButton fluid v-model="userFacingDocsPreference" :options="userFacingDocsOptions" />
        </div>

        <div class="flex justify-start gap-2 mt-2 mb-2">
            <span class="p-card-subtitle">Select which repo is the documentation repo with all your markdown. Dev-Docs will
                integrate with this repo to help you manage your docs</span>
            <Select v-if="userFacingDocsPreference == 'Setup User-Facing Docs'" v-model="selectedDocRepo" fluid
                :options="repos" placeholder="Select a repository" />
        </div>

        <div class="flex justify-end gap-2 mt-2 mb-2">
            <Button v-if="userFacingDocsPreference == 'Setup User-Facing Docs'" type="button" label="Save"
                @click="submitFormData"></Button>
            <Button v-else type="button" label="Skip" severity="secondary"
                @click="userFacingSetupModal = false"></Button>
        </div>
    </Dialog>
</template>

<script>

export default {
    data: () => ({
        openSetupModal: false,
        userFacingSetupModal: false,
        username: '',
        userFacingDocsPreference: 'Setup User-Facing Docs',
        userFacingDocsOptions: ['Setup User-Facing Docs', 'Skip User-Facing Docs'],
        org: null,
        orgs: [],
        repos: [],
        userInfo: null,
        selectedDocRepo: null,
        items: [
            {
                label: 'Refresh',
                icon: 'pi pi-refresh'
            },
            {
                label: 'Export',
                icon: 'pi pi-upload'
            }
        ]
    }),
    methods: {
        async close() {
            this.$authInstance.logout()
            // this.openSetupModal = false;
        },
        toggle(event) {
            this.$refs.menu.toggle(event);
        },
        setOrg(options) {
            this.org = options.org
        },
        async submitFormData() {
            var myHeaders = new Headers();
            try {
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                let repo = this.selectedDocRepo

                var raw = JSON.stringify({repo, github_app: true});
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                let setResponse = await fetch(`${url}/external_githubs`, requestOptions)
                this.$router.push('/users_docs');
            } catch (e) {
                console.log(e)
                return
            }
        },
        skipDocs() {
            this.$router.push('/dash');
        },
        async completeSetup() {
            if (!this.org || !this.repos.length) return;

            const linkPromises = this.repos.map(repo => {
                return this.$authInstance.linkRepoToOrg({
                    org: this.org,
                    repo,
                });
            });
            await Promise.all(linkPromises);
            this.openSetupModal = false
            this.userFacingSetupModal = true;
            //this.$router.push('/dash');
        },

    },
    async mounted() {
        const [
            org,
            orgs,
            repos,
            userInfo,
        ] = await Promise.all([
            this.$authInstance.getOrg(),
            this.$authInstance.getOrgs(),
            this.$authInstance.getRepos(),
            this.$authInstance.userinfo(),
        ]);

        this.org = org;
        this.orgs = orgs;
        this.repos = repos;
        this.username = userInfo.displayName || userInfo.name;

        this.openSetupModal = true;
    }
}

</script>