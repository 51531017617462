<template>
  <node-view-wrapper ref="wholenode" class="draggable-item mermaid-block"
    :style="`resize: both !important; overflow: auto; width: auto; max-width: 100vh;`">

    <el-dialog v-model="dialogFormVisible" title="Edit Diagram">
      <el-form label-position="top" :model="form">
          <el-form-item label="Activity name">
            <el-input v-model="value" autosize type="textarea"
              placeholder="Please input" />
          </el-form-item>
          <el-form-item label="Activity name">
            <el-button ref="myBtn" @click="reRender()">
              Render diagram
            </el-button>
          </el-form-item>
        </el-form>

    </el-dialog>

    <div class="drag-handle" contenteditable="false" draggable="true"  data-drag-handle/>
    <el-row ref="diagramholder" style="min-width: 100% !important">
      <el-col :span="24">
        <div>

          <el-button @click="dialogFormVisible = true" class="absolute top-0 right-0">
            <el-icon>
              <Hide />
            </el-icon>
          </el-button>
        </div>
        <div v-if="value && reRendered" ref="mermaid" class="mermaid mermaid-diagram-block">
          {{ value }}
        </div>
      </el-col>
    </el-row>
  </node-view-wrapper>
</template>

<script>

import { View, Hide } from '@element-plus/icons-vue'
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3'
export default {
  components: {
    NodeViewWrapper,
    View,
    Hide,
  },
  computed: {
    isDraggable() {
      return this.node?.attrs?.isDraggable
    },
    trueWidth() {
      return this.$refs?.imageholder?.$el.clientWidth || this.width
    },
  },
  props: { ...nodeViewProps },
  data: () => ({
    value: `
        graph TD
        A[Client] --> B[Load Balancer]
        B --> C[Server01]
        B --> D[Server02]
        `,
    showTextarea: true,
    newValue: null,
    form: {},
    width: null,
    isReadOnly: null,
    reRendered: null,
    dialogFormVisible: false
  }),
  async mounted() {
    const component = this
    
    if (this.node.attrs.saved) this.showTextarea = false
    if (this.node.attrs.mermaidDiagram)
      this.value = this.node.attrs.mermaidDiagram.trim()
    await this.$nextTick()
    
    await this.reRender()
    var mermaidBlock = this.$refs.mermaid
    
    if(this.node.attrs.height && this.node.attrs.width){
      mermaidBlock.firstChild.style.height = this.node.attrs.height
      mermaidBlock.firstChild.style.width = this.node.attrs.width
      this.$refs.wholenode.$el.style.width = this.node.attrs.width
    } 

    new ResizeObserver(function(){
            
            
            
            mermaidBlock.firstChild.style.height = component.$refs.diagramholder.$el.clientHeight
            mermaidBlock.firstChild.style.width = component.$refs.diagramholder.$el.clientWidth
            component.updateAttributes({width: mermaidBlock.firstChild.style.width })
            component.updateAttributes({height: mermaidBlock.firstChild.style.height})
            
    }).observe(component.$refs.diagramholder.$el)
   
  },
  watch: {
    value(val) {
      this.reRendered = false
    },
  },
  methods: {
    async reRender() {
      this.value = this.value.trim()
      this.reRendered = true
      await mermaid.mermaidAPI.reinitialize()
      mermaid.init({ noteMargin: 10 }, '.mermaid-diagram-block')
      this.updateAttributes({ mermaidDiagram: this.value })
      this.updateAttributes({ saved: true })
    },
    hideOrShow() {
      this.showTextarea = !this.showTextarea
      this.reRender()
    },
  },
}
</script>

<style lang="scss">
.el-row {
  margin-bottom: 20px;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.draggable-item.mermaid-block {
  display: flex;
  padding: 0.5rem;
  margin: 0.5rem 0;
  border-radius: 0.5rem;
  background: white;
  resize: both !important;
  overflow: auto;
  padding: 1em;
  height: 300px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  box-shadow:
    0 0 0 1px rgba(0, 0, 0, 0.05),
    0px 10px 20px rgba(0, 0, 0, 0.1),
  ;

element.style {
    color: black;
    min-height: 31px;
    height: 94px;
}
.el-textarea__inner {
    color: black !important;
    position: relative;
    display: block;
    resize: vertical;
    padding: 5px 11px;
    line-height: 1.5;
    box-sizing: border-box;
    width: 100%;
    font-size: inherit;
    font-family: inherit;
    color: var(--vscode-foreground) !important;
    background-color: var(--vscode-editor-background) !important;
    background-image: none;
    -webkit-appearance: none;
    box-shadow: 0 0 0 1pxvar(--el-input-border-color,var(--el-border-color)) inset;
    border-radius: var(--el-input-border-radius,var(--el-border-radius-base));
    transition: var(--el-transition-box-shadow);
    border: none;
}
}

.mermaid-block {
  .mermaid-diagram-block {}

  svg {
    min-width: auto !important;
    max-width: 100% !important;
  }
}
</style>
