

var mermaidInstance = function() {
    this.mermaid = undefined
    this.initialize = async function() {
        // await this.mermaid.initialize({startOnLoad:true});
        //await this.mermaid.init({noteMargin: 10}, ".someOtherClass");
    }
}


export default mermaidInstance

