<template>
    <floating-menu
        :editor="editor"
        :tippy-options="{
            placement: 'right',
            offset: [0, -36],
        }"
        v-if="editor"
        class="flex flex-row items-center gap-[0.5rem]"
        :should-show="getShouldShow"
    >

        <img
            :class="[
                'w-[2rem] h-[1rem] relative cursor-pointer',
                defaultTextVisible ? '' : 'ml-[-3px]',
            ]"
            draggable="false"
            :src="newLineIcon"
            alt="new line"
            :onClick="toggleTextDropdown"
        />
        <p
            class="text-[0.875rem] text-[rgba(255,255,255,0.50)]"
            v-if="isTableNode && defaultTextVisible"
        >

        </p>

        <p
            class="text-[0.875rem] text-[rgba(255,255,255,0.50)]"
            v-else-if="defaultTextVisible"
        >
            Write something, or press ‘ / ‘ for commands...
        </p>
        <TextTableDropdown
            ref="dropdown"
            @close="closeTextDropdown"
            :style="dropdownPosition"
            class="absolute flex flex-col gap-[0.31rem] rounded-[0.5rem] bg-[#1C1C1C] px-[0.31rem] py-[0.94rem] w-[13.5rem]"
            v-if="isTableNode && isTextDropdownVisible"
            v-on-click-outside="closeTextDropdown"
            :editor="editor"
        />
        <TextDropdown
            ref="dropdown"
            @close="closeTextDropdown"
            :style="dropdownPosition"
            class="absolute flex flex-col gap-[0.31rem] rounded-[0.5rem] bg-[#1C1C1C] px-[0.31rem] py-[0.94rem] w-[13.5rem]"
            v-else-if="isTextDropdownVisible"
            v-on-click-outside="closeTextDropdown"
            :editor="editor"
        />
    </floating-menu>
</template>

<script setup>
import {
    computed,
    nextTick,
    onBeforeUnmount,
    onMounted,
    ref,
    watch,
} from 'vue';
import { FloatingMenu } from '@tiptap/vue-3';
import { vOnClickOutside } from '@vueuse/components';

import TextDropdown from '@/components/EditorComponents/TextDropdown.vue';
import TextTableDropdown from '@/components/EditorComponents/TextTableDropdown.vue';
import newLineIcon from '@/assets/icon-new-line.svg';

const isTextDropdownVisible = ref(false);
const defaultTextVisible = ref(true);
const dropdown = ref(null);
const dropdownPosition = ref({
    top: 0,
    left: '38px',
});

const props = defineProps({
    editor: {
        type: Object,
        required: true,
    },
});

const toggleTextDropdown = () => {
    isTextDropdownVisible.value = !isTextDropdownVisible.value;
};

const closeTextDropdown = () => {
    isTextDropdownVisible.value = false;
};

const isTableNode = computed(() => {
    return props.editor.isActive("table");
});


const updateDropdownPosition = () => {
    if (!dropdown.value?.$el) return;

    const dropdownRect = dropdown.value.$el.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    const spaceBelow = viewportHeight - dropdownRect.bottom;

    dropdownPosition.value = spaceBelow < 100
        ? { bottom: 0, left: '38px' }
        : { top: 0, left: '38px'};
};

const getShouldShow = ({
    editor,
    oldState,
    state,
    view,
}) => {
    const { selection } = state;
    const resolvedPos = editor.state.doc.resolve(selection.from);
    const lineStart = resolvedPos.before();
    let lineEnd = resolvedPos.after();
    const docSize = editor.state.doc.content.size;
    if (lineEnd > docSize) {
        lineEnd = docSize
    }
    const lineContent = editor.state.doc.textBetween(
        lineStart,
        lineEnd,
        '\n', // Block separator
        ' ',  // Leaf separator
    );

    let shouldShow = false;
    switch (lineContent) {
        case '/':
            shouldShow = true;
            defaultTextVisible.value = false;
            isTextDropdownVisible.value = true;
            break;
        case '':
            shouldShow = true;
            defaultTextVisible.value = true;
            isTextDropdownVisible.value = false;
            break;
        default:
            shouldShow = false;
            defaultTextVisible.value = false;
            isTextDropdownVisible.value = false;
            break;
    }

    return shouldShow;
};

watch(isTextDropdownVisible, (newVal) => {
    if (newVal) {
        nextTick(() => updateDropdownPosition());
    }
});

onMounted(() => {
    window.addEventListener('resize', updateDropdownPosition);
});

onBeforeUnmount(() => {
    window.removeEventListener('resize', updateDropdownPosition);
});

</script>