<template>
  <NodeViewWrapper>
    <div class="table-wrapper">
      <div class="table-controls">
        <button @click="editor.chain().focus().addColumnBefore().run()">
          Add Column Before
        </button>
        <button @click="editor.chain().focus().addColumnAfter().run()">
          Add Column After
        </button>
        <button @click="editor.chain().focus().deleteColumn().run()">
          Delete Column
        </button>
        <button @click="editor.chain().focus().addRowBefore().run()">
          Add Row Before
        </button>
        <button @click="editor.chain().focus().addRowAfter().run()">
          Add Row After
        </button>
        <button @click="editor.chain().focus().deleteRow().run()">
          Delete Row
        </button>
        <button @click="editor.chain().focus().deleteTable().run()">
          Delete Table
        </button>
      </div>
    </div>
  </NodeViewWrapper>
</template>

<script>
import { nodeViewProps, NodeViewContent, NodeViewWrapper } from "@tiptap/vue-3";
export default {
  components: { NodeViewWrapper, NodeViewContent },
  name: "TableComponent",
  props: nodeViewProps,
  mounted() {
    console.log(this.node);
  },
};
</script>

<style scoped>
.table-wrapper {
  margin: 1rem 0;
}

.table-controls {
  margin-bottom: 0.5rem;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.table-controls button {
  padding: 0.25rem 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
}

.table-controls button:hover {
  background: #f5f5f5;
}

.custom-table {
  border-collapse: collapse;
  width: 100%;
}

.custom-table td {
  border: 1px solid #ddd;
  padding: 0.5rem;
}
</style>
