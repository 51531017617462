<template>
    <div class="persona-analysis">
        <h2 class="header">{{ title }}</h2>

        <div class="question">
            How well do my docs meet the needs of this {{ keyValue }}
        </div>

        <div class="overview-section">
            <h3>Overview</h3>
            <div class="overview-table">
                <table>
                    <thead>
                        <tr>
                            <th>{{keyValue}}</th>
                            <th>Grade</th>
                            <th>Comments</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="analysisObject in data[analysisObjectsKey]" :key="analysisObject[keyValue]">
                            <td>{{ analysisObject[keyValue] }}</td>
                            <td>
                                <span :class="['grade', getGradeClass(analysisObject.grade)]">
                                    {{ analysisObject.grade }}
                                </span>
                            </td>
                            <td>{{ getGradeComment(analysisObject.grade) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="detailed-feedback">
            <h3>Detailed feedback by {{ keyValue }}</h3>

            <div class="feedback-grid">
                <div v-for="analysisObject in data[analysisObjectsKey]" :key="analysisObject[keyValue]"
                    class="feedback-card">
                    <Accordion>
                        <AccordionPanel value="0">
                            <AccordionHeader>
                                <div>

                                    <span class="persona-name">{{ analysisObject[keyValue] }}</span>
                                    <span>
                                        <span> Grade: </span>
                                        <span :class="['grade', getGradeClass(analysisObject.grade)]">
                                            {{ analysisObject.grade }}
                                        </span>
                                    </span>
                                </div>

                            </AccordionHeader>
                            <AccordionContent>
                                <Skeleton v-if="batchUpdateLoading" height="10rem" fluid />
                                <div v-else class="card-content">
                                    <div class="section">
                                        <h4>Suggestions</h4>
                                        <ul>
                                            <li v-for="(suggestion, index) in analysisObject.suggestions" :key="index">
                                                {{ suggestion }}
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="section">
                                        <h4>AI-recommended page additions</h4>
                                        <div v-for="file in analysisObject.missing_files" :key="file.path"
                                            class="file-path">
                                            {{ file.path }}
                                        </div>
                                    </div>

                                    <Button @click="handleBatchUpdate(analysisObject)" severity="secondary" class="generate-btn">Generate suggested pages</Button>
                                </div>
                            </AccordionContent>
                        </AccordionPanel>
                    </Accordion>



                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { getOwnerAndRepo } from '../plugins/devdocsBackendService.js';

export default {
    name: "PersonaAnalysis",
    props: {
        data: {
            type: Object,
            required: true,
        },
        keyValue: {
            type: String,
            required: true,
        },
        analysisObjectsKey: {
            type: String,
            required: true,
        },
        branch: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        publishedBranch: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            batchUpdateLoading: false,
            batchUpdateResults: null,
        };
    },
    methods: {
        getGradeClass(grade) {
            return (
                {
                    A: "grade-a",
                    B: "grade-b",
                    C: "grade-c",
                    D: "grade-d",
                }[grade] || ""
            );
        },
        async handleBatchUpdate(analysisObject) {
            this.batchUpdateLoading = true;
            console.log('analysisObject', analysisObject);
            try {
                const token = await this.$authInstance.getToken();
                const url = await this.$authInstance.getBaseUrl();
                const { repo, owner } = await getOwnerAndRepo();

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        repo,
                        owner,
                        publishedBranch: this.publishedBranch || 'main',
                        draftBranch: this.branch,
                        auditResults: {
                            persona: analysisObject,
                        }
                    })
                };

                const response = await fetch(`${url}/llm_txt/batch_update`, requestOptions);
                const result = await response.json();
                this.batchUpdateResults = result;
                let newFiles = analysisObject.missing_files.map(file => file.path);
                localStorage.setItem('newFiles', JSON.stringify(newFiles));
                this.$emit('update', result);
                // Refresh analysis after batch update
                //await this.refreshData();
            } catch (error) {
                console.error('Error performing batch update:', error);
                this.$toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Failed to create missing files',
                    life: 3000
                });
            } finally {
                this.batchUpdateLoading = false;
            }
        },
        getGradeComment(grade) {
            return (
                {
                    A: "Meets the needs perfectly",
                    B: "Meets needs with improvement",
                    C: "Needs significant improvement",
                    D: "Requires major revision",
                }[grade] || ""
            );
        },
    },
};
</script>

<style scoped>
.persona-analysis {
    background: #1e1e1e;
    color: #e1e1e1;
    padding: 2rem;
    font-family: system-ui, -apple-system, sans-serif;
}

.header {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.highlight {
    background: #ffd700;
    color: black;
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
}

.question {
    color: #cccccc;
    margin-bottom: 2rem;
}

.overview-table table {
    width: 100%;
    border-collapse: collapse;
    margin: 1rem 0;
}

.overview-table th,
.overview-table td {
    padding: 1rem;
    text-align: left;
    border: 1px solid #333;
}

.grade {
    padding: 0.2rem 0.5rem;
    border-radius: 50%;
    font-weight: bold;
}

.grade-a {
    background: #4caf50;
    color: white;
}

.grade-b {
    background: #ffc107;
    color: black;
}

.grade-c {
    background: #ff9800;
    color: black;
}

.grade-d {
    background: #f44336;
    color: white;
}

.feedback-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
    margin-top: 1rem;
}

.feedback-card {
    border-radius: 8px;
    overflow: hidden;
}

.card-header {
    width: 100%;
    background: #333;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-content {
    padding: 1rem;
}

.section {
    margin-bottom: 1.5rem;
}

.section h4 {
    color: #cccccc;
    margin-bottom: 0.5rem;
}

.file-path {
    background: #333;
    padding: 0.5rem;
    margin: 0.5rem 0;
    border-radius: 4px;
    font-family: monospace;
}

.generate-btn {
    padding: 0.75rem;
    border-color: none;
    border-radius: 0.5rem;
    background: #1C1C1C;
    color: white;
    cursor: pointer;
    margin-top: 1rem;
}

.generate-btn:hover {
    background: #5a5a5a;
}

ul {
    list-style-type: none;
    padding-left: 0;
}

li {
    margin-bottom: 0.5rem;
    color: #cccccc;
}
</style>
