<template>
  <Button style="color: #888888" @click="showSearchDialog = true" text>
    <icon class="pi pi-search" />
  </Button>
  <Dialog :header="title" :style="{ width: '50rem' }" v-model:visible="showSearchDialog">

    <Listbox
      v-model="selectedPublishedDraft"
      :options="items"
      filter
      class="w-full"
      :virtualScrollerOptions="{ itemSize: 80 }"
      listStyle="height:200px"
    >
      <template #option="slotProps">
        <router-link @click="handleLinkClick"
          :key="slotProps.option"
          :to="{
            path: '/editor',
            query: {
              file: slotProps.option,
              branch: branch,
              repo: `${owner}%2F${repo}`,
            },
          }"
          class="text-[0.875rem] hover:text-white cursor-pointer"
        >
          <span class="pi pi-file-edit mr-2" />
          <span v-tooltip.left="slotProps.option">{{
            removeFileExtension(slotProps.option)
          }}</span>
        </router-link>
      </template>
    </Listbox>
  </Dialog>
</template>

<script setup>
import { ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
const showSearchDialog = ref(false);
const selectedPublishedDraft = ref(null);
const router = useRouter();
const route = useRoute();

watch(
  () => route,
  async (to, from) => {
    showSearchDialog.value = false;
  },
  { deep: true } // Need deep watching since route is an object
);

const removeFileExtension = (filename) => {
    return filename ? filename.replace(/\.[^/.]+$/, '') : filename;
};

const handleLinkClick = () => {
      setTimeout(() => {
      showSearchDialog.value = false;
    }, 300);
};

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  branch: {
    type: String,
    required: true,
  },
  owner: {
    type: String,
    required: true,
  },
  repo: {
    type: String,
    required: true,
  },
  repoUrl: {
    type: String,
    required: true,
  },
});



</script>
