<template>

    <figure class="px-10 pt-10 pb-10">
        <h2 class="text-left">OpenAPI</h2>
        <h3 class="mt-4 text-left subpage-heading">Upload your OpenAPI spec and generate API docs</h3>
    </figure>

    <div class="px-10">
        <FileUpload name="demo[]" :fileLimit="1" :customUpload="true" choose-label="Add file" @uploader="customUploader"
            :multiple="true" :maxFileSize="1000000" @before-send="postExampleCollection">
            <template #content="{ files, uploadedFiles, removeUploadedFileCallback, removeFileCallback }">

                <Skeleton height="4rem" v-if="loading">
                </Skeleton>
                <div v-else>
                    <DataTable v-if="files.length > 0" :value="files" tableStyle="min-width: 50rem">
                        <Column field="name" header="Name"></Column>
                    </DataTable>

                    <DataTable :value="fileList" tableStyle="min-width: 50rem">
                        <Column field="name" header="Name"></Column>
                    </DataTable>
                </div>


            </template>
        </FileUpload>
    </div>



</template>

<script>

import { createFileContent} from '@/plugins/devdocsBackendService';
export default {
    data() {
        return {
            apiUrl: null,
            addFile: false,
            token: '',
            newFileName: '',
            fileList: [],
            loading: false
        };
    },
    methods: {
        upload() {
            this.$refs.fileUpload.upload();
        },
        handleFileSelect(event) {
            this.newFileName = event.files[0].name.split('.')[0];
        },
        async customUploader(event) {
            try {
                this.loading = true
                console.log("do i get called")
                console.log("this is the event", event)
                let component = this
                const file = event.files[0];
                const reader = new FileReader();
                let blob = await fetch(file.objectURL).then((r) => r.blob());

                reader.readAsDataURL(blob);

                reader.onloadend = async () => {
                    const base64data = reader.result;
                    console.log("this is the base64", base64data)
                    // You can perform additional operations with the base64data here
                    await component.uploadSuccess(base64data, file);
                    const configsObject = await component.getAPIConfigs();
                    component.fileList = configsObject.configs;
                    component.loading = false
                };
            } catch {
                this.loading = false
            }

        },
        async uploadSuccess(base64data, uploadFile) {
            const uploadFiles = [uploadFile];
            const extension = uploadFile.name.includes('.yaml') ? '.yaml' : '.json';
            this.fileList = uploadFiles;

            const apiConfig = {};

            for (const file of uploadFiles) {
                const fileExtension = file.name.includes('.yaml') ? '.yaml' : '.json';
                const nameWithoutExt = file.name.split(fileExtension)[0];
                apiConfig[nameWithoutExt] = {
                    specPath: `examples/${nameWithoutExt}${fileExtension}`,
                    outputDir: `docs/api/${nameWithoutExt}`,
                };
            }

            await this.saveConfig(apiConfig);
            await this.postExampleCollection(uploadFile)
        },
        async getAPIConfigs() {
            const myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            try {
                const token = await this.$authInstance.getToken();

                myHeaders.append('Content-Type', 'application/json');
                if (token) {
                    myHeaders.append('Authorization', `Bearer ${token}`);
                }

                const requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow',
                };
                const url = await this.$authInstance.getBaseUrl();
                const response = await fetch(`${url}/openapi`, requestOptions);
                const jsonResponse = await response.json();
                return jsonResponse;
            } catch (e) {
                console.error(e);
            }
        },
        async saveConfig(apiConfig) {
            const myHeaders = new Headers();
            try {
                const token = await this.$authInstance.getToken();

                myHeaders.append('Content-Type', 'application/json');
                if (token) {
                    myHeaders.append('Authorization', `Bearer ${token}`);
                }
                const raw = JSON.stringify(apiConfig);

                const requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow',
                };
                const url = await this.$authInstance.getBaseUrl();
                await fetch(`${url}/openapi_config`, requestOptions);
            } catch (e) {
                console.error(e);
            }
        },
        async postExampleCollection(apiConfig) {
            try {
                const myHeaders = new Headers();
                apiConfig.legend = "yaka"
                console.group("this is the api config", apiConfig)

                const formData = new FormData();
                // add a new field to the form data
                formData.append('newname', apiConfig.legend);
                formData.append('file', apiConfig);
                const url = await this.$authInstance.getBaseUrl();
                const token = await this.$authInstance.getToken();


                if (token) {
                    myHeaders.append('Authorization', `Bearer ${token}`);
                }
                const response = await fetch(`${url}/openapi`, {
                    method: 'POST',
                    headers: myHeaders,
                    body: formData,
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log('File uploaded successfully', data);
                } else {
                    console.error('Error uploading file:', response.statusText);
                }
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        },
    },
    async mounted() {
        const baseUrl = await this.$authInstance.getBaseUrl();
        this.token = await this.$authInstance.getToken();
        this.apiUrl = `${baseUrl}/openapi`;
        const configsObject = await this.getAPIConfigs();
        this.fileList = configsObject.configs;
    },
    beforeUnmount() {
        this.$emit('closeapi');
    },
};
</script>

<style>
.p-fileupload-file-thumbnail {
    display: none;
}

.p-datatable-tbody>tr {
    background: transparent !important;
}

.p-datatable-header-cell {
    background: transparent !important;
}

.p-datatable-table-container {
    border-radius: 0.625rem;
    border: 0.5px solid rgba(162, 161, 165, 0.75);

}

.p-datatable-table {}

.p-fileupload-advanced {
    background: transparent !important;
    border: none !important;
    margin-bottom: 0px;
}

.p-fileupload-header {
    margin-bottom: 0px;
    justify-content: flex-end !important
}
</style>