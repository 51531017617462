import { mergeAttributes, Node } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-3";
import ComponentHolder from "../EditorComponents/ComponentHolder.vue";

export default Node.create({
  name: "componentHolder",
  inline: true,
  group: 'inline',
 

  draggable: true,

  addAttributes() {
    return {
      name: {
        default: null,
        parseHTML: element => element.getAttribute('name'),
        renderHTML: attributes => ({
          name: attributes.name
        })
      },
      original: {
        default: null,
        parseHTML: element => element.getAttribute('original'),
        renderHTML: attributes => ({
          original: attributes.original
        })
      },
      props: {
        default: '{}',
        parseHTML: element => element.getAttribute('props') || '{}',
        renderHTML: attributes => ({
          props: attributes.props
        })
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: 'component',
        getAttrs: element => ({
          name: element.getAttribute('name'),
          original: element.getAttribute('original'),
          props: element.getAttribute('props') || '{}'
        })
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['component', mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return VueNodeViewRenderer(ComponentHolder)
  },
})
