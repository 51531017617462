<template>
  <node-view-wrapper>
    <span class="component-holder">{{ node.attrs.name }} component</span>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3'

export default {
  components: {
    NodeViewWrapper,
  },

  props: nodeViewProps,

  data() {
    return {
      showProps: false
    }
  },
  mounted() {
    console.log("custom node mounted")
    console.log(this.node)
  },
  computed: {
    formattedProps() {
      try {
        const props = JSON.parse(this.node.attrs.props)
        return JSON.stringify(props, null, 2)
      } catch (e) {
        return '{}'
      }
    }
  },

  methods: {
    toggleProps() {
      this.showProps = !this.showProps
    }
  }
}
</script>

<style scoped>
:deep(.component-holder),
:deep(.component-holder *) {
  margin-bottom: 0 !important;
}
.component-holder {
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 0.25rem;
}

.react-component-placeholder {
  background: #f5f5f5;
  padding: 1rem;
  border-radius: 4px;
}

.component-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.component-name {
  font-weight: bold;
  color: #666;
}

.edit-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  padding: 0.25rem;
}

.edit-button:hover {
  color: #333;
}

.props-display {
  background: #fff;
  padding: 0.5rem;
  border-radius: 4px;
  font-family: monospace;
  font-size: 0.9em;
  margin-top: 0.5rem;
}

pre {
  margin: 0;
  white-space: pre-wrap;
}
</style>
