<template>
    <div>
        <div v-if="analysisInfo">
            <div class="flex items-center justify-between mb-4">
                <span class="text-sm text-gray-400" v-if="isLoadedFromCache">
                    Using cached data
                </span>
                <div class="flex gap-2">
                    <Button icon="pi pi-refresh" @click="refreshData" label="Run Again"
                        class="text-white p-button-sm" text />
                    <Button icon="pi pi-trash" @click="clearData" label="Clear" class="text-white p-button-sm"
                        text />
                </div>
            </div>
            <ScrollPanel style="width: 100%; height: 100vh">
                <PersonaAnalysis v-if="personaAnalysis" @update="refreshEditor" :branch="draftBranch"
                    :published-branch="publishedBranch" analysisObjectsKey="personas_addressed_in_docs"
                    title="Persona analysis" keyValue="persona" :data="personaAnalysis" />
                <PersonaAnalysis v-if="seoAnalysis" :branch="draftBranch" @update="refreshEditor" :published-branch="publishedBranch"
                    analysisObjectsKey="seo_keywords_analysis" title="SEO analysis" keyValue="keyword"
                    :data="seoAnalysis" />
                <div v-if="batchUpdateResults?.length" class="mt-4 card">
                    <h3>File Creation Results</h3>
                    <div v-for="result in batchUpdateResults" :key="result.path" class="flex items-center gap-2 mb-2">
                        <i class="text-green-500 pi pi-check" v-if="result.status === 'completed'"></i>
                        <span>{{ result.path }} - {{ result.action }}</span>
                    </div>
                </div>
            </ScrollPanel>
        </div>
        <div v-else class="flex flex-col p-fluid gap-[1.25rem] mt-[1.25rem]">
            <!-- <div class="flex flex-col gap-[0.62rem]">
                <div>Additional Analysis Parameters</div>
                <div style="display: flex; position: relative">
                    <Textarea placeholder="Add additional context for analysis (optional)"
                        class="w-full px-[0.63rem] py-[0.44rem] rounded-[0.5rem] bg-[#1C1C1C] min-h-[9.375rem] !border-none"
                        autoResize v-model="additionalPersona" variant="filled" autofocus fluid rows="2" />
                </div>
            </div> -->

            <div v-if="showAnalysisButton" class="flex flex-col self-end justify-end w-full">
                <label class="mb-4 p-card-subtitle">Analyze your documentation content based on users persona as well as SEO to help uplevel your docs and fill in missing gaps</label>
                <Button label="Analyze" @click="analyzeContent" class="w-fit" />
            </div>
        </div>

        <Skeleton class="mt-2 mb-2" height="10rem" v-if="loading" fluid />
    </div>
</template>

<script>
import Skeleton from 'primevue/skeleton';
import ScrollPanel from 'primevue/scrollpanel';
import indexDbService from '../plugins/indexDbService.js';
import { getOwnerAndRepo, getFileContent, generateLLMTxt } from '../plugins/devdocsBackendService.js';
import PersonaAnalysis from './PersonaAnalysis.vue';

export default {
    props: {
        draftBranch: {
            type: String,
            required: true
        },
        publishedBranch: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            showAnalysisButton: false,
            analysisInfo: null,
            additionalPersona: '',
            documentationRepo: null,
            personaAnalysis: null,
            seoAnalysis: null,
            isLoadedFromCache: false,
            batchUpdateLoading: false,
            batchUpdateResults: null,
        };
    },
    components: {
        Skeleton,
        ScrollPanel,
        PersonaAnalysis
    },
    async mounted() {
        this.loading = true;
        let documentationRepo = await getOwnerAndRepo();
        this.documentationRepo = documentationRepo
        await this.getLLMtxt(documentationRepo);
        await this.checkExistingData();
        this.loading = false;
        this.showAnalysisButton = true;
    },
    methods: {
        async checkExistingData() {
            const cachedData = await indexDbService.getTableData('analysisData');
            if (cachedData) {
                this.analysisInfo = cachedData.analysisInfo;
                this.personaAnalysis = cachedData.personaAnalysis;
                this.seoAnalysis = cachedData.seoAnalysis;
                this.isLoadedFromCache = true;
            }
        },
        async getLLMtxt(documentationRepo) {
            try {
            let { owner, repo } = documentationRepo;
            let llmTxtJSONFile;
            try {
                llmTxtJSONFile = await getFileContent({
                    branch: this.publishedBranch,
                    fileName: "llmText.json",
                    owner: owner,
                    repo: repo
                })
            } catch (error) {
                console.error('Error getting llmText.json:', error);
            }
            console.log("what is the llmTxtJSONFile", llmTxtJSONFile)
            if(!llmTxtJSONFile || llmTxtJSONFile?.error) {
                await generateLLMTxt();
            }

            } catch (error) {
                console.error('Error getting llmText.json:', error);
            }
            return
        },
        async clearData() {
            this.analysisInfo = null;
            this.personaAnalysis = null;
            this.seoAnalysis = null;
            this.isLoadedFromCache = false;
            await indexDbService.clearStore();
            this.additionalPersona = '';
        },
        async refreshData() {
            this.analysisInfo = null;
            this.isLoadedFromCache = false;
            await this.analyzeContent();
        },
        refreshEditor() {
            this.$emit('draft');
        },
        async analyzeContent() {
            this.loading = true;
            try {
                const token = await this.$authInstance.getToken();
                const url = await this.$authInstance.getBaseUrl();

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        repo: this.documentationRepo.repo,
                        owner: this.documentationRepo.owner,
                        branch: this.publishedBranch,
                        additionalPersona: this.additionalPersona
                    })
                };

                const response = await fetch(`${url}/llm_txt/analysis`, requestOptions);
                const result = await response.json();

                this.analysisInfo = result;
                this.personaAnalysis = result.persona;
                this.seoAnalysis = result.seo;
                this.isLoadedFromCache = false;

                // Save to IndexDB
                await indexDbService.saveTableData('analysisData', {
                    analysisInfo: this.analysisInfo,
                    personaAnalysis: this.personaAnalysis,
                    seoAnalysis: this.seoAnalysis
                });
            } catch (error) {
                console.error('Error analyzing content:', error);
                // Try to load from IndexDB if network fails
                const cachedData = await indexDbService.getTableData('analysisData');
                if (cachedData) {
                    this.analysisInfo = cachedData.analysisInfo;
                    this.personaAnalysis = cachedData.personaAnalysis;
                    this.seoAnalysis = cachedData.seoAnalysis;
                    this.isLoadedFromCache = true;
                }
            }
            this.loading = false;
        }
    }
};
</script>

<style scoped>
.card {
    background-color: #2c2c2c;
    padding: 1rem;
    border-radius: 0.5rem;
}

h3 {
    margin-bottom: 1rem;
    color: #ffffff;
}
</style>
