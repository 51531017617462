<template>
      <ProgressBar class="mt-2 mb-2" :value="progressPercentage" />
      <span class="p-card-subtitle">{{ processedItems }} of {{ items?.length }} {{ itemType }} being processed</span>
</template>
  
  <script>
  export default {
    props: {
      items: {
        type: Array,
        required: true,
      },
      processedItems: {
        type: Number,
        required: true,
      },
      itemType: {
        type: String,
        required: true,
      }
    },
    computed: {
      progressPercentage() {
        if (!this.items.length) return 0;
        return (this.processedItems / this.items.length) * 100;
      },
    },
  };
  </script>