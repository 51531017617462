<template>
    <Toast />
    <Skeleton v-if="loading" width="100%" height="10rem"></Skeleton>
    <div v-else class="flex flex-col gap-[1.25rem] mt-[1.25rem]">
        <div style="display: flex; flex-direction: column;" class="flex flex-col gap-[1.25rem]">
            <div style="display: flex; flex-direction: column !important; gap: 0.62em;" class="">
                <label for="folders">What existing docs do you want to use as context</label>
                <!-- <TreeSelect fluid filter filterMode="lenient" v-model="selectedFolders" :options="treeNodes"
                    selectionMode="checkbox" placeholder="Select item(s)" class="w-full !border-none !bg-[#1c1c1c]" /> -->
                    <ContextSelectTree v-if="folderOptions?.length > 0 && updatedContextSource" :data="folderObject"
                    :additional-items="repoContextItems" v-model="selectedFolders"
                    @selection-change="handleSelectionItemChange" class="mt-[0.62rem] !bg-[#1C1C1C]" />
                    <Skeleton class="mt-2" v-else height="2rem" fluid />
            </div>
        </div>
        <div class="">
            <div class="flex items-center p-[0.62rem] gap-[0.62rem]">
                <Checkbox v-model="checkCode" :binary="true" inputId="binary"
                    label="Verify documentation matches current code" />
                <label for="size_normal">Click here to use code as context. Once checked, select codefiles from the dropdown above.</label>
            </div>
        </div>


        <div class="flex flex-col gap-[0.62rem]">
            <span class="w-full p-card-subtitle">Your task for Dev-Docs AI</span>
            <div style="display: flex; position: relative;">
                <Textarea
                    placeholder="Type your document prompt here."
                    class="w-full rounded-[0.5rem] !border-none !bg-[#1c1c1c] px-[0.63rem] py-[0.44rem] h-[9.375rem]"
                    autoResize v-model="value" variant="filled" autofocus fluid rows="2" />
            </div>
        </div>
        <div class="flex flex-col justify-start items-start gap-[0.62rem]">
            <div class="self-stretch text-white/50 text-sm font-normal font-['Inter'] leading-[21px]">
                Popular prompts:
            </div>
            <div class="self-stretch flex flex-col justify-start items-start gap-[0.62rem]">
                <div v-for="(prompt, index) in popularPrompts" :key="index"
                    class="px-[0.44rem] py-[0.06rem] bg-[#7984eb]/25 rounded-[0.5rem] border border-[#7984eb] justify-start items-start inline-flex cursor-pointer">
                    <div @click="setPrompt(prompt)"
                        class="text-[#d3d3d3] text-xs font-normal font-['Inter'] leading-[18px]">
                        {{ prompt }}
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-end mt-4">
            <Button @click="generateContent" label="Submit" />
        </div>

    </div>
</template>


<script>
import ApiKey from '@/views/ApiKey.vue';
import { Editor, EditorContent, VueNodeViewRenderer, FloatingMenu } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import MarkdownEditor from '@/components/NewMarkdownEditor.vue';
import ContextSelectTree from './ContextSelectTree.vue';
import { getFileContent, getCustomFiles, getOwnerAndRepo, getSyncedDocsRepo } from '../plugins/devdocsBackendService.js';
import { useToast } from 'primevue/usetoast';
import Toast from 'primevue/toast';

function findParentPaths(items) {
    const paths = Array.isArray(items) ? items : [];
    return paths.filter((path, index) => {
        return !paths.some((otherPath, otherIndex) => {
            if (index === otherIndex) return false;
            return path.startsWith(otherPath + '/');
        });
    });
}

export default {
    props: {
        repo: String,
        branch: String
    },
    components: {
        EditorContent,
        FloatingMenu,
        MarkdownEditor,
        ContextSelectTree
    },
    data() {
        return {
            value: '',
            additionalOptionsValue: null,
            checkCode: false,
            locationValue: 'docs',
            items: ["docs", "blog"],
            loading: false,
            response: null,
            documentationRepo: {},
            githubRepository: null,
            selectedFolders: {},
            folderObject: {},
            folderOptions: [],
            codeFiles : [],
            editorOptions: [
                "Edit raw markdown", "Use rich text editor(preview)"
            ],
            editorSelectvalue: 'Edit raw markdown',
            openapiOptions: [],
            openapiRoutes: [],
            draftContent: null,
            additonalContextOptions: [
                { name: "openapi files", value: "openapi files" }
            ],
            treeNodes: [],
            editor: null,
            updatedContextSource: true,
            repoContextItems: [],
            popularPrompts: [
                "Provide a high-level overview of core features and use cases for different developer roles.",
                "Offer a step-by-step troubleshooting guide or link to a FAQ.",
                "Generate a blog post on most recent updates"
            ]
        }
    },
    async mounted() {
        let component = this
        let documentationRepo = await getOwnerAndRepo()
        this.documentationRepo = documentationRepo
        this.githubRepository = `${documentationRepo?.owner}/${documentationRepo?.repo}`
        await this.getFolders()
        let items = await this.fetchOpenApi()
        console.log("what is the items", items)
        this.openapiOptions = items
    },
    watch: {
        additionalOptionsValue: function (val) {
            console.log("what is the value", val)
        },
        openapiRoutes: function (val) {
            console.log("what is the value", val)
        },
        selectedFolders: function (val) {
            console.log("what is the value", val)
        },
        checkCode: {
            async handler(newData) {
                try {
                    if (newData) {
                        this.updatedContextSource = false
                        let repos = await this.getRepos()
                        let newRepoContextItems = []
                        console.log("what is repos", repos)
                        for (let repo of repos) {
                            console.log("what is repo here", repo)
                            console.log("what is this documentation repo", this.documentationRepo)
                            if(this.documentationRepo.repo == repo.name && this.documentationRepo.owner == repo.owner){
                                continue
                            }
                            let files = await getCustomFiles({ branch: repo.default_branch, owner: repo.owner, repo: repo.name })
                            let repoContextItem = { label: repo.full_name, files: files?.files || [], category: repo.full_name }
                            newRepoContextItems.push(repoContextItem)
                        }
                        this.repoContextItems = newRepoContextItems
                        this.updatedContextSource = true
                    }

                } catch (e) {
                    this.updatedContextSource = true
                }

            }
        }
    },
    methods: {
        search(event) {
            this.items = ["docs", "blog"]
        },
        async getRepos() {
            var myHeaders = new Headers();
            try {
                var token = await this.$authInstance.getToken()

                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                let url = await this.$authInstance.getBaseUrl()
                let response = await fetch(`${url}/github_app_repos`, requestOptions)
                let jsonResponse = await response.json()
                //this.repos = jsonResponse?.repos
                return jsonResponse?.repos
            } catch (e) {
                //this.folderOptions = []
            }
        },
        handleSelectionItemChange(value) {
            // Handle the selections here
            console.log('Selected folders:', value);
            this.selectedFolders = value.selections;
            let byCategory = value.byCategory
            this.codeFiles = byCategory
            // You can store the selections in your component's data
            // or emit them to a parent component if needed

        },
        setPrompt(prompt) {
            this.value = prompt
        },
        // async generateTask() {
        //     this.loading = true
        //     const myHeaders = new Headers();
        //     let page = false
        //     let sub_folder = "docs"
        //     let task = this.value
        //     try {
        //         let org = await this.$authInstance.getOrg()
        //         let token = await this.$authInstance.getToken()
        //         myHeaders.append("Content-Type", "application/json");

        //         if (token) {
        //             myHeaders.append("Authorization", `Bearer ${token}`);
        //         }




        //         let requestBody = { task, githubRepository: this.githubRepository, page, apiKey: "dsfds", branch: "dev-docs-drafts" }
        //         console.log("this is the select fdolders", this.selectedFolders)
        //         if (Object.keys(this.selectedFolders)?.length > 0) {
        //             requestBody.folders = this.processSelectedFolders(this.selectedFolders)
        //         }
        //         if (this.openapiRoutes?.length > 0 && this.additionalOptionsValue?.length) {
        //             requestBody.openApiFiles = this.openapiRoutes
        //         }

        //         const raw = JSON.stringify(requestBody);

        //         const requestOptions = {
        //             method: 'POST',
        //             headers: myHeaders,
        //             body: raw,
        //             redirect: 'follow'  // 'as const' ensures TypeScript understands this as a literal type
        //         };

        //         let url = await this.$authInstance.getBaseUrl()

        //         const saveResponseTwo = await fetch(`${url}/task`, requestOptions);
        //         this.loading = false
        //         this.value = ''
        //         const result = await saveResponseTwo.json();
        //         let branch = result?.pageCreationMetadata?.head?.ref

        //         this.$router.replace({
        //             query: {
        //                 branch: encodeURIComponent(branch || ''),
        //                 file: encodeURIComponent(`${sub_folder}/${result?.path || ''}.md`),
        //                 repo: encodeURIComponent(githubRepository || '')
        //             }
        //         })
        //         this.$emit('draft', result)
        //         //this.$emit('draft', result.content)
        //         // this.response = { content: result.content, branch: result.branch, pullUrl: `https://github.com/${githubRepository}/pulls` }
        //         // this.draftContent = this.response.content
        //         // this.editor.commands.setContent(result.content)
        //     } catch (e) {
        //         console.log(e)
        //         return {}
        //     }
        // },
        processSelectedFolders(folderObj) {
            // Get all checked folders
            console.log("this is the folder obj", folderObj)
            let checkedFolders = Object.entries(folderObj)
                .filter(([key, value]) => value.checked)
                .map(([key]) => key);

            // Remove the "0" entry if it exists
            checkedFolders = checkedFolders.filter(folder => folder !== "0");

            // Sort by path length to process shortest (parent) paths first
            checkedFolders.sort((a, b) => a.split('/').length - b.split('/').length);

            const result = [];
            console.log("this is the checked folders", checkedFolders)
            for (let folder of checkedFolders) {
                // Check if this folder is a parent of any already included folders
                const isParent = result.some(existingFolder =>
                    existingFolder.startsWith(folder + '/'));

                // Check if this folder is a child of any already included folders
                const isChild = result.some(existingFolder =>
                    folder.startsWith(existingFolder + '/'));

                // Only add if it's not a parent or child of existing folders
                if (!isParent && !isChild) {
                    result.push(folder);
                }
            }

            return result;
        },
        async getFolders() {
            var myHeaders = new Headers();
            try {
                var token = await this.$authInstance.getToken()

                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var response = await fetch(`${url}/folders`, requestOptions)
                var jsonResponse = await response.json()
                this.folderOptions = jsonResponse
                //this.treeNodes = await this.turnIntoNodes(jsonResponse)
                this.folderObject = { category: "Documentation", files: jsonResponse }
            } catch (e) {
                this.folderOptions = []
            }
        },
        async turnIntoNodes(folderArray) {

            // Helper function to create a node
            const createNode = (key, label, isFile = false) => ({
                key,
                label,
                data: key,
                icon: isFile ? 'pi pi-fw pi-file' : 'pi pi-fw pi-folder',
                children: []
            });

            // Create root node
            const root = createNode('0', 'Documents');

            for (let path of folderArray) {
                const parts = path.split('/');
                let currentLevel = root.children;
                let currentPath = '';

                // Iterate through each part of the path
                parts.forEach((part, index) => {
                    currentPath = currentPath ? `${currentPath}/${part}` : part;
                    const isFile = index === parts.length - 1 && part.includes('.');

                    // Check if node already exists at current level
                    let existingNode = currentLevel.find(node => node.label === part);

                    if (!existingNode) {
                        // Create new node
                        existingNode = createNode(currentPath, part, isFile);
                        currentLevel.push(existingNode);
                    }


                    // Move to next level
                    currentLevel = existingNode.children;
                });
            }

            return [root];


        },
        async generateContent() {
            this.loading = true
            var myHeaders = new Headers();
            try {
                let org = await this.$authInstance.getOrg()
                let token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                let sub_folder = "docs"
                let githubRepository = this.githubRepository
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`);
                }

                console.log("this is the selected folders", this.selectedFolders)
                //let folders = this.processSelectedFolders(this.selectedFolders)
                let folders = this.codeFiles["Documentation"]
                folders = findParentPaths(folders)
                let codeObjects = this.codeFiles
                delete codeObjects["Documentation"]
                let task = this.value
                let page = false
                let requestBody = { task, githubRepository, folders, codeObjects, page, apiKey: "this_is_not_a_real_api_key_but_tech_debt", branch: this.branch }
                const raw = JSON.stringify(requestBody);
                const requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'  // 'as const' ensures TypeScript understands this as a literal type
                };

                let url = await this.$authInstance.getBaseUrl()
                console.log("this raw object", raw)
                const saveResponseTwo = await fetch(`${url}/task`, requestOptions);
                if (saveResponseTwo.status !== 200) {
                    const { message: responseTwoMessage } = await saveResponseTwo.json();
                    if (responseTwoMessage) {
                        this.$toast.add({
                            severity: "Danger",
                            summary: "Error",
                            detail: responseTwoMessage,
                            life: 3000,
                        });
                    }
                }
                this.loading = false
                this.value = ''
                const result = await saveResponseTwo.json();
                let branch = result?.pageCreationMetadata?.head?.ref

                this.$router.replace({
                    query: {
                        branch: encodeURIComponent(branch || ''),
                        file: encodeURIComponent(`${sub_folder}/${result?.path || ''}.md`),
                        repo: encodeURIComponent(githubRepository || '')
                    }
                })
                let newFiles = [`${sub_folder}/${result?.path || ''}.md`]
                localStorage.setItem('newFiles', JSON.stringify(newFiles));
                this.$emit('draft', result)
            } catch (e) {
                console.log(e)
                return {}
            }
        },
        async updateBlock() {
            let content = this.draftContent
            // this.updateAttributes({ saved: true })
            var myHeaders = new Headers();
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var raw = JSON.stringify({ message: options.historicalMessage || this.command })
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/proxy_url/message`, requestOptions)
                var result = await saveResponseTwo.json()


                this.output = result?.content
                this.command = ""


            } catch (e) {

            }
        },
        async fetchOpenApi() {
            try {
                let org = await this.$authInstance.getOrg()
                let token = await this.$authInstance.getToken()
                let url = await this.$authInstance.getBaseUrl()
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`);
                }
                const requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                const response = await fetch(`${url}/openapi`, requestOptions);
                const result = await response.json();
                console.log("what is the result", result)
                return result.configs
            } catch (e) {
                return {}
            }
        }
    }
}

</script>