<template>
    <div class="card flex justify-start published-drafts">
        <PanelMenu style="overflow: hidden;" :model="menuItems" class="w-[9.89rem]">
            <template #item="{ item }">
                <div class="mb-2" v-if="item.main">
                    <div class="flex flex-col items-end gap-[0.31rem]">
                        <div class="flex w-full justify-between">
                            <div class="flex gap-2 items-center">
                                <span class="text-[0.875rem] font-semibold">
                                    {{ item.label }}
                                </span>
                            </div>
                            <div class="flex gap-[0.2rem]">
                                <SearchDraftsComponent v-if="items?.length > 0"
                                    :items="items"
                                    title="Search Published Drafts"
                                    :branch="branch"
                                    :owner="owner"
                                    :repo="repo"
                                    :repoUrl="repoUrl"
                                />
                                <a :href="repoUrl" target="_blank" class="flex flex-row items-center gap-[0.31rem]">
                                    <img class="w-[1.25rem] h-[1.25rem]" :src="githubIcon" alt="github" />
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
                <router-link v-else :key="item.label" :to="{
                    path: '/editor',
                    query: {
                        file: item.label,
                        branch: branch,
                        repo: `${owner}%2F${repo}`
                    }
                }" class="text-[0.875rem] hover:text-white cursor-pointer mb-[4rem]">
                        <span :class="item.icon" />
                        <span class="ml-2" v-tooltip.left="item.label">{{ removeFileExtension(item.label) }}</span>
                </router-link>
            </template>
        </PanelMenu>
    </div>
</template>

<script setup>
import githubIcon from '@/assets/icon-github.svg';
import { computed } from "vue";
import SearchDraftsComponent from './SearchDraftsComponent.vue';

const removeFileExtension = (filename) => {
    return filename ? filename.replace(/\.[^/.]+$/, '') : filename;
};

const props = defineProps({
    items: {
        type: Array,
        required: true
    },
    branch: {
        type: String,
        required: true
    },
    owner: {
        type: String,
        required: true
    },
    repo: {
        type: String,
        required: true
    },
    repoUrl: {
        type: String,
        required: true
    }
});

const menuItems = computed(() => [{
    label: 'Published',
    icon: 'pi pi-angle-down',
    main: true,
    items: props.items.map(item => ({
        label: item,
        icon: 'pi pi-file-edit'
    }))
}]);
</script>

<style lang="scss">
.published-drafts {
    .p-panelmenu-panel {
        background: transparent;
        border: none;
    }

    .p-panelmenu-item {
        margin-bottom: 2rem;
        padding: 0px;
    }

    .p-tooltip-text {
		background: #1c1c1c !important;
		color: #d3d3d3 !important;
	}

    .p-tooltip {
        background: #1c1c1c !important; 
    }

    .p-tooltip-text {
        background: #1c1c1c !important;
        color: #d3d3d3 !important;
    }
}
</style>