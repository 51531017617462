import { Table } from '@tiptap/extension-table'
import { Node } from '@tiptap/core'
import component from "../EditorComponents/TableComponent.vue";
import { VueNodeViewRenderer } from '@tiptap/vue-3'

export default Table.extend({
  name: 'customTable',

  addNodeView() {
    return VueNodeViewRenderer(component)
  }
})
